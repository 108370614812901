* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
  background-color: #111;
}

html,
body {
  overflow: hidden;
}

html,
body,
#__next,
main {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.header {
  max-width: 500px;
  padding-inline: 2rem;
}

.header > img {
  display: block;
  margin: auto;
  max-width: 100%;
}

.header > h2 {
  font-family: 'TWK Everett', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 144%;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  background-color: var(--lk-bg);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

footer a,
h2 a {
  color: #ff6352;
  text-decoration-color: #a33529;
  text-underline-offset: 0.125em;
}

footer a:hover,
h2 a {
  text-decoration-color: #ff6352;
}

h2 a {
  text-decoration: none;
}

/* globals.css */

.rooms-list {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 2rem;
}

.room-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.room-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.room-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.room-info {
  padding: 1rem;
}

.room-info h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.2rem;
}

.room-info p {
  margin: 0;
  color: #666;
}

.room-info span {
  display: block;
  margin-top: 0.5rem;
  font-weight: bold;
}

/* Add this to globals.css */

.skeleton-loader {
  display: grid;
  gap: 1rem;
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
}

.skeleton-thumbnail {
  width: 100%;
  height: 150px;
  background-color: #ccc;
}

.skeleton-info {
  padding: 1rem;
}

.skeleton-title {
  width: 60%;
  height: 1rem;
  background-color: #e0e0e0;
  margin-bottom: 0.5rem;
}

.skeleton-text {
  width: 100%;
  height: 0.8rem;
  background-color: #e0e0e0;
  margin-bottom: 0.3rem;
}
